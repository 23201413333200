import React from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import EmptyTopNavbar from "../components/Nav/EmptyTopNavbar";
import products from "../components/Data/ProductList.json";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ProductDiscription() {
  const navigate = useNavigate();
  const { id } = useParams();
  //const product = products.find((p) => p.id === parseInt(id));
  const product = products.find((p) => p.id === id);
  if (!product) {
    return <h2>Product not found</h2>;
  }

  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <>
      <EmptyTopNavbar />

      <Wrapper id="productdiscription" className="container flexSpaceCenter">

        <LeftSide className="flexCenter">
          <div>
            <img src={product.image} alt={product.name} />
          </div>
        </LeftSide>
        <RightSide>
          <div style={{ display: "flex", gap: "20px", alignItems: "center", marginBottom: "50px" }}>
            <h5 style={{ margin: 0, cursor: "pointer", textDecoration: "underline" }} onClick={() => handleClick("/#home")}>Home</h5>
            <h5> / </h5>
            <h5 style={{ margin: 0, cursor: "pointer", textDecoration: "underline" }} onClick={() => handleClick("/#products")}>Product List</h5>
          </div>

          <div style={{ display: "flex", gap: "20px"}}>
          <h3  dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(product.name),
            }}></h3>
            <h3> , </h3>
            <h3 dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(product.description),
            }}></h3>
          </div>
          <hr />
          <div>
            <p dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(product.price),
            }}>
            </p>

            <br />

            <p dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(product.descriptionall),
            }}>
            </p>
          </div>
        </RightSide>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  margin-top: 120px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }

   /* Media query for PC screens */
  @media (min-width: 1024px) {
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Media query for smaller screens */
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 35%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 65%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;