import React from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import products from "../Data/ProductList.json";
import SAPOTA from "../../assets/img/add/sapota.png";
import { useNavigate } from "react-router-dom";

export default function Product() {
  const navigate = useNavigate();
  const handleClick = (productId) => {
    navigate("/product/" + productId , { replace: true });
  };
  

  return (
    <Wrapper id="products">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Products</h1>
          </HeaderInfo>
          <div className="row textCenter">
          {products.map((product) => (
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={product.image}
                title={product.name}
                text={product.description}
                action={() => handleClick(product.id)}
              />
            </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={SAPOTA} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about Sapota - Chikoo</h4>
              <h2 className="font40 extraBold">Sapota - Chikoo  Banifits</h2>
              <p className="font16">
                Sapota is high on calories providing 83 calories per 100 grams. A good source of dietary fibre,
                the pulp of this fruit functions as an excellentlaxative.
                It is loaded with a rich array of vitamins A, C, niacin, folate and pantothenic acid and minerals iron, potassium and copper.
                The host of plant compound tannins in sapota possesses strong antioxidant, anti-inflammatory, antiviral, antibacterial and antiparasitic effects.
                The plethora of essential nutrients in sapota promotes overall health and well-being.
              </p>
              <hr></hr>

              <h2 className="font20">FOB  /  CIF Rates</h2>
              <br></br>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{fontStyle:""}}>FOB Price:</td>
                  <td>US$1.4 - US$1.6 Per Kg</td>
                </tr>
                <tr>
                  <td>Min.Order Quantity:</td>
                  <td>500 Kg</td>
                </tr>
                <tr>
                  <td>Supply Ability:	</td>
                  <td>1000 Kg Per Week</td>
                </tr>
                <tr>
                  <td>Port:</td>
                  <td>JNPT Mumbai / Hazira Surat</td>
                </tr>
                <tr>
                  <td>Payment Terms:</td>
                  <td>40% Advanced TT && 60% Advanced on BL</td>
                </tr>
              </table>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
