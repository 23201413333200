import React from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
export default function ProjectBox({ img, title, text, action }) {
  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action} style={{ width: "309px", height: "360px" }}>
        <img className="radius8 center" src={img} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold" onClick={action}>{title}</h3>
      <p className="font16" onClick={action} dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(text),
      }}></p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;